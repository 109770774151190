@import url(fonts/heady/heady.css);
@import url(static/css/checkbox.css);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --fusya: rgb(255, 0, 140);
  --softgray: #ebebeb;
  --black: rgb(0,0,0);
  --black2:rgb(22, 22, 22);
  --dark: rgb(59, 59, 59);
  --dark2: rgb(79, 79, 79);
  --dark3: rgb(105, 105, 105);
  --dark4: rgb(129, 127, 127);
  --white :rgb(255, 255, 255);
  --white2 :rgb(247, 247, 247);
  --white3 :rgb(227, 227, 227);
  --red: rgb(173, 0, 0);
  --green: rgb(0, 129, 62);
}

.t_left {
  text-align: left;
}
.t_right {
  text-align: right;
}
.t_center {
  text-align: center;
}

* {
  box-sizing: border-box;
}
p {
  margin: 0;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  line-height: 1.1;
}

p + *,
* + p,
h1 + *,
* + h1,
h2 + *,
* + h2,
h3 + *,
* + h3,
h4 + *,
* + h4,
h5 + *,
* + h5 {
  margin-top: 1.2rem;
}

a.stick {
  position: absolute;
  z-index:3;
  inset: 0;
  font-size: 0;
  margin: 0 !important;
}


html, body {
  padding: 0;
  margin: 0;
}

html, body {
  font: normal 1rem/1.25 'Poppins', system-ui;
}
input, textarea, select, button {
  font: normal 1rem/1.25 'Poppins', system-ui;
}

[type="text"],
[type="email"],
[type="password"],
[type="date"],
[type="search"],
textarea,
select {
  display: block;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--dark4);
  background-color: var(--white2);
  color: var(--black);
  width: 100%;
  outline: none;
}

body.dark-mode [type="text"],
body.dark-mode [type="email"],
body.dark-mode [type="password"],
body.dark-mode [type="date"],
body.dark-mode [type="search"],
body.dark-mode textarea,
body.dark-mode select {
  background-color: var(--black2);
  color: var(--white3);
  border-color: var(--black2);
}

body.dark-mode #post-popup [type="text"],
body.dark-mode #post-popup  [type="email"],
body.dark-mode #post-popup  [type="password"],
body.dark-mode #post-popup  [type="date"],
body.dark-mode #post-popup  [type="search"],
body.dark-mode #post-popup  textarea,
body.dark-mode #post-popup  select {
  background-color: var(--dark);
  color: var(--white);
  border-color: var(--dark);
}

textarea {
  resize: vertical;
}

body {
  background-color: #ffffff;
  padding: 100px 0 0;
}
body.dark-mode {
  background-color: var(--dark);
  color: var(--white2)
}
body.freeze {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color:#000000;
  text-decoration: none;
}
body.dark-mode a,
body.dark-mode button {
  color: var(--white);
}
body.dark-mode a:hover {
  color: var(--white2);
} 

body.dark-mode h1 > a {
  color: var(--fusya);
}
body.dark-mode h1 > a:hover {
  color: var(--white2);
} 

.pg {
  max-width: 1440px;
  margin-left:auto;
  margin-right:auto;
}

main > header {
  position: fixed;
  z-index: 500;
  inset: 0 0 auto 0;
  padding: 20px;
  background-color: rgb(212, 212, 212);
}
main > header > .pg {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
main > header > .pg > * {
  flex:auto;
}
main > header > .pg > .logo ~ * {
  max-width: max-content;
}
main > header > .pg > * + * {
  margin: unset;
}
main > header.fixme {
  background-color: rgba(255,255,255,.85);
  box-shadow: 5px 0 15px 0 rgba(0,0,0,.15);
  backdrop-filter: blur(5px); 
  -webkit-backdrop-filter: blur(5px);
}
main > header.popup-inside,
main > header.fixme.popup-inside {
  backdrop-filter: unset; 
  -webkit-backdrop-filter:unset;
}


body.dark-mode main > header {
  background-color: var(--black2);
}

body.dark-mode main > header.fixme {
  background-color: rgba(0,0,0,.65);
  box-shadow: 5px 0 15px 0 rgba(0,0,0,.35);
}

footer {
  padding: 2.5vw 0;
  background-color: #585858;
} 
footer > .pg {
  text-align: center;
}

body.dark-mode footer {
  background-color: var(--black2);
}
* + footer {
  margin-top: 3vw;
}

footer, footer div, footer table, footer a {
  color: #cccccc;
}
footer a:hover {
  color: #ffffff;
}

h1.logo {
  margin:0;
  font-size: 1.6rem;
  line-height: 1;
}
h1.logo > a.go-home-link > em:first-child {
  font-weight: normal;
  font-size: .8rem;
}
h1.logo > a > i.heady {
  font-size: 1.6rem;
}

a.go-home-link {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}
a.go-home-link > em {
  display: block;
  font-style: normal;
}

aside {
  position: fixed;
  z-index: 1001;
  inset: 0 -360px 0 auto;
  width: 320px;
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.15);
  display: flex;
  flex-direction: column;
}
body.dark-mode aside {
  background-color: var(--black2);
}
aside.active {
  inset: 0 0 0 auto;
}
aside:after {
  content: "";
  width: 0;
  position: absolute;
  z-index: 1;
  inset: 0 100% 0 auto;
  background-color: rgba(255,255,255,.35);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); 
}
aside.active:after {
  width: calc(100vw - 320px);
}

body.dark-mode aside:after {
  background-color: rgba(0,0,0,.35);
}

.the-content {
  max-width: 1280px;
  margin: 0 auto;
}

.the-content > * + * {
  margin-top: 3vw;
}

.grid-article {display: grid;grid-gap:10px;grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));grid-auto-rows: auto;grid-auto-flow: dense;}
.grid-article img {
  display: block;
}
.grid-article > article {
  position: relative;
  z-index: 1;
}
.grid-article > article:hover {
  z-index: 2;
  transform: scale(1.05);
}

.grid-article.grid-news {
  gap: 30px;
}

.grid-article.grid-news > article,
.grid-article.grid-categories > article {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 0 12px 0px rgba(0,0,0,.15);
  animation: flippo .5s normal forwards ease-in-out;
  opacity: 0;
}
body.dark-mode .grid-article.grid-news > article,
body.dark-mode .grid-article.grid-categories > article {
  background-color: var(--dark2);
}
.grid-article.grid-news > article > picture,
.grid-article.grid-categories > article > picture {
  display: block;
  border-radius: 6px;
  overflow: hidden;

}
.grid-news > article h3,
.grid-categories > article h2 {
  font-size: 1.2rem;
  color: var(--fusya);
}
body.dark-mode .grid-news > article h3,
body.dark-mode .grid-categories > article h2 {
  font-size: 1.2rem;
  color: var(--white);
}
#post-detail {
  border-radius: 24px;
}

.infinite-scroll-component {
  overflow: unset !important;
}

.sipa-popup {
  position: fixed;
  z-index: 100;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.35);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin:0 !important;
}
.sipa-popup.passive {
  visibility: hidden;
  content-visibility: hidden;
}
.sipa-popup.active {
  visibility: visible;
  content-visibility: visible;
}

.sipa-popup > .in-box {
  background-color: #ffffff;
  padding: 36px;
  border-radius: 6px;
  box-shadow: 0 0 16px 0 rgba(0,0,0,.15);
  position: relative;
  z-index: 5;
  max-width: 1440px;
  transition-delay: 700ms;
}
.sipa-popup.share-me > .in-box {
  max-width: 360px;
}
.sipa-popup.login-popup > .in-box {
  max-width: 640px;
}

body.dark-mode .sipa-popup > .in-box {
  background-color: var(--dark2);
  box-shadow: 0 0 16px 0 rgba(0,0,0,.45);
}
.sipa-popup.passive > .in-box {
  transform: scale(0) translateY(101vh);
}
.sipa-popup.active > .in-box {
  transform: scale(1) translateY(0);
}

.sipa-popup button.closeme {
  position: absolute;
  z-index: 10;
  inset: 15px 15px auto auto;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 1;
}

.share-links {
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
  z-index: 1;
}
.share-links i.heady {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  border: 1px solid rgba(0,0,0,.35);
  border-radius: 3px;
  background-color: rgba(255,255,255,1);
}
body.dark-mode .share-links i.heady {
  border-color: var(--dark4);
  background-color: var(--dark);
}
.share-links i.heady:hover {
  background-color: rgba(0,0,0,.05);
}
body.dark-mode .share-links i.heady:hover {
  background-color: var(--black2);
}

.share-links > a,
.share-links > i.heady {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}
.share-links > i.heady.icon-link {
  font-size: 0;
}
.share-links > i.heady.icon-link:before {
  font-size: 1.5rem;
  line-height: 1;
}

#post-detail > h1.post-title{
  font-size: 2rem;
}

#post-detail .post-image,
#post-detail .post-image > img {
  display: block;
  width: 100%;
}

.copied {
  position: absolute;
  z-index: 10;
  display: block;
  padding: 5px 20px;
  border-radius: 16px;
  background-color: #cccccc;
  font-weight: bold;
  opacity: 0;
  animation: zipcik .35s normal forwards ease-in-out;
}
body.dark-mode .copied {
  background-color: var(--black);
  color: var(--white3);
}

.share-links .copied {
  right: 0;
}

button[type="button"]:not([class*='slick-']) {
  appearance: none;
  padding: 0;
  border:0;
  outline: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
}
button[type="button"].hamburger {
  min-width: 36px;
  min-height: 36px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--fusya);
  background-color: #ffffff;
  color:var(--fusya) ;
}
body.dark-mode button[type="button"].hamburger {
  background-color: var(--dark3);
  color: var(--white2);
  border-color: var(--dark4);
}
button[type="button"].hamburger.active,
button[type="button"].hamburger:hover {
  background-color: var(--fusya);
  color: #ffffff;
}
body.dark-mode button[type="button"].hamburger:hover,
body.dark-mode button[type="button"].hamburger.active {
  background-color: var(--fusya);
  color: var(--white);
  border-color: var(--fusya);
}

button[type="button"].go-reply {
  padding: 5px 10px;
  line-height: 1;
  background-color: var(--white);
  border-radius: 16px;
  border:1px solid var(--dark4)
}
body.dark-mode button[type="button"].go-reply {
  background-color: var(--black2);
}
button[type="button"].go-reply:hover {
  background-color: var(--fusya);
  border-color: var(--fusya);
  color: var(--white);
}
body.dark-mode button[type="button"].go-reply:hover {
  background-color: var(--black);
  border-color: var(--dark2);
  color: var(--white);
}
button[type="button"].go-reply.cancel {
  color: var(--red);
  border-color:var(--red) ;
}
body.dark-mode button[type="button"].go-reply.cancel {
  background-color: var(--red);
  color: var(--white);
  border-color:var(--red) ;
}
button[type="button"].go-reply.cancel:hover {
  color: var(--white);
}
body.dark-mode button[type="button"].go-reply.cancel:hover {
  color: var(--white);
}


button[type="submit"],
button[type="button"].go-write-comment,
button[type="button"].go-login {
  padding: 10px 20px;
  background-color: var(--fusya);
  color: var(--white);
  border: 1px solid var(--fusya);
  cursor: pointer;
  font-weight: bold;
}
button[type="submit"]:hover,
button[type="button"]go-write-comment:hover,
button[type="button"].go-login:hover  {
  background-color: var(--white);
  color: var(--fusya);
}
body.dark-mode button[type="submit"],
body.dark-mode button[type="button"].go-write-comment,
body.dark-mode button[type="button"].go-login  {
  background-color: var(--black);
  color: var(--white);
  border: 1px solid var(--black);
  cursor: pointer;
  font-weight: bold;
}
body.dark-mode button[type="submit"]:hover,
body.dark-mode button[type="button"].go-write-comment:hover,
body.dark-mode button[type="button"].go-login:hover {
  background-color: var(--fusya);
  color: var(--white);
}

button[type="button"].go-write-comment {
  position: absolute;
  z-index: 1;
  inset: auto 40px auto auto;
}

.post-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.post-header > *,
.post-header > button.go-back {
  flex:1;
  margin: 0;
  padding: 24px;
}
.post-header > a.go-back,
.post-header > button.go-back {
  max-width: max-content;
  font-size: 0;
}
.post-header > a.go-back > i.heady,
.post-header > button.go-back > i.heady {
  font-size: 2rem;
}
.post-header > .share-action {
  max-width: max-content;
}
.post-header > .share-action {
  font-size: 0;
}
.post-header > .share-action > i.heady {
  font-size: 2rem;
  cursor: pointer;
}

.post-header h1 + * {
  margin: 0;
}

.post-header > .post-date {
  min-width: 100%;
  order:-1;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom:1px solid rgba(0,0,0,.15);
  background-color: #f8f8f8;
}

body.dark-mode .post-header > .post-date {
  background-color: var(--black);
  color: var(--white3);
  border-bottom-color:rgba(255,255,255,.35);
}

#post-detail > .post-header,
#post-popup > div > .post-header {
  margin: -26px -26px 26px -26px;
  border-bottom: 1px solid rgba(0,0,0,.15);
}
#post-detail > .post-header {
  margin: 0 0 26px 0;
}
body.dark-mode #post-detail > .post-header,
body.dark-mode  #post-popup > div > .post-header {
  border-bottom-color:rgba(255,255,255,.35);
}
#post-detail > .post-header > h1,
#post-popup > div > .post-header > h1 {
  border: 1px solid rgba(0,0,0,.15);
  border-width: 0 1px;
  font-size: 2rem;
}
body.dark-mode #post-detail > .post-header > h1,
body.dark-mode #post-popup > div > .post-header > h1 {
  border-color:rgba(255,255,255,.35);
}
#post-popup > div > .post-header {
  background-color: #ffffff;
  position: sticky;
  z-index: 10;
  top: -26px;
}
body.dark-mode #post-popup > div > .post-header {
  background-color: var(--black) ;
}

aside {
  padding: 20px;
}
#main-nav ul,
#main-nav ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
}
#main-nav ul > li > a {
  display: block;
  padding: 8px 16px;
  background-color: #bdbdbd;
  font-weight: 600;
  font-size:1.2rem;
  border-radius: 6px;
}
#main-nav ul > li > a.active {
  font-weight: bold;
}
body.dark-mode #main-nav ul > li > a  {
  background-color: var(--dark4);
  color: var(--black);
}
#main-nav ul > li + li {
  margin-top: 5px;
}
#main-nav ul > li > a:hover,
body.dark-mode #main-nav ul > li > a:hover,
#main-nav ul > li > a.active,
body.dark-mode #main-nav ul > li > a.active {
  background-color: var(--fusya);
  color:#ffffff;
}

button[type="button"].close-hamburger {
  font-size: 1rem;
  color: rgb(166, 0, 0);
}
aside button[type="button"].close-hamburger {
  position: absolute;
  z-index: 30;
  inset:20px auto auto -20px;
  padding: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ffffff;
  line-height: 1;
}
body.dark-mode aside button[type="button"].close-hamburger {
  background-color: var(--black2);
  color: var(--white2);
}

button[type="button"].go-news-popup {
  position: relative;
  z-index: 5;
  font-size: 1.4rem;
}

#post-popup {
  position: fixed;
  z-index: 120;
  inset: 76px auto 0 0;
  width: 50vw;
  background-color: #ffffff;
  box-shadow: 10px 0 15px 0 rgba(0,0,0,.15);
  animation: fromleft .7s normal forwards ease-in-out;
}
body.dark-mode #post-popup {
  background-color: var(--black2);
}
#post-popup.passive {
  animation: toleft .7s normal forwards ease-in-out;
}
#post-popup > .inside {
  overflow: auto;
  padding: 26px;
  max-height: 100%;
}

.processing-message {
  font-size: 1.2rem;
  text-align: center;
}
.processing-message > i.heady {
  display: block;
  font-size: 3rem;
  color: var(--fusya);
  margin: 0 auto 20px auto;
  width: max-content;
  animation: turnforever 3s normal infinite;
} 
.news-item .processing-message,
.plase-login .right .processing-message,
.register-box .right .processing-message {
  position: absolute;
  z-index: 30;
  inset: -5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.75);
  backdrop-filter: blur(5px) grayscale(100%);
  -webkit-backdrop-filter: blur(5px) grayscale(100%);
  border-radius: 16px;
}
.news-item .post-meta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-between;
  gap: 10px;
  align-items: center;
}
body.dark-mode .news-item .processing-message,
body.dark-mode .plase-login .right .processing-message,
body.dark-mode .register-box .right .processing-message  {
  background-color: rgba(0,0,0,.45);
}

.post-date {
  font-size: .86rem;
  color: #585858;
  font-style: italic;
}
body.dark-mode .post-date {
  color: var(--white3);
}

aside .rocket {
  padding-top: 26px;
  margin-top: auto;
  text-align: center;
  font-size: .8rem;
}

.rocket > * {
  display: block;
  text-align: center;
}

.rocket > i.heady {
  font-size: 6rem;
  margin-bottom: 16px;
  color: var(--fusya);
  text-shadow: 4px 4px rgba(0,0,0,.15);
}

button[type="button"].go-news-popup.on-desktop {
  position: absolute;
  background-color: #ffffff;
  font-size: 0;
  margin: -57px 0 0 10px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
body.dark-mode button[type="button"].go-news-popup.on-desktop {
  background-color: var(--dark);
}

button[type="button"].go-news-popup.on-desktop > i.heady {
  font-size: 1.2rem;
}

.news-item button[type="button"].go-news-popup.on-desktop {
  opacity: 0;
  visibility: hidden;
}
.news-item:hover button[type="button"].go-news-popup.on-desktop {
  opacity: 1;
  visibility: visible;
}

button[type="button"].go-news-popup.on-mobile {
  font-size: 0;
  position: absolute;
  z-index: 30;
  inset: 0;
  margin: 0;
  opacity: 0;
}
.no-more-content {
  text-align: center;
}
.no-more-content i.heady,
.there-is-an-error i.heady {
  display: block;
  width: max-content;
  margin: 0 auto 16px auto;
  font-size: 3rem;
  color: var(--dark3);
}
.there-is-an-error i.heady {
  color: var(--white);
}
.there-is-an-error a {
  display: none;
}

.no-more-content.no-comment i.heady {
  font-size: 1.6rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}

article.no-more-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body.dark-mode .no-more-content i.heady,
body.dark-mode .there-is-an-error i.heady  {
  color: var(--white3);
}

.slick-slide > div {
  position: relative;
  z-index: 1;
}
.slick-slide > div > picture {
  display: block;
}

.slick-slide > div > picture > img {
  width: 100%;
  display: block;
}
.slick-slide > div > .desc {
  position: absolute;
  z-index: 2;
  inset: auto auto 20px 20px;
  max-width: 50%;
  padding: 36px;
  background-color: rgba(255,255,255,.65);
  border-radius: 12px 24px 12px 0;
} 
body.dark-mode .slick-slide > div > .desc {
  background-color: rgba(0,0,0,.65);
} 
.slick-slide > div > .desc h2 {
  font-size: 1.6rem;
}

.slick-slider.home-promo-slide .slick-list {
  box-shadow: 0 0 12px 0px rgba(0,0,0,.15);
  border-radius: 12px;
}
button.slick-next, button.slick-prev {
  width: max-content;
  height: max-content;
  line-height: 1;
  padding: 10px 20px;
  background-color: rgba(0,0,0,.35);
  display: inline-block;
  border-radius: 50%;
  inset: auto 20px 20px auto;
  z-index: 10;
}
button.slick-prev {
  inset: auto 20px 80px auto;
}
.slick-next:before, .slick-prev:before {
  font-family: 'heady';
  content: '\F105';
  font-size: 2rem;
  color: var(--fusya);
}
.slick-prev:before {
  content: '\F104';
}
.slick-dots li button {
  width: 20px;
  height: 5px;
  display: inline-block;
  background-color: var(--dark);
  padding: 0;
  border-radius: 3px;
}
.slick-dots li.slick-active button {
  background-color: var(--fusya);
}
.slick-dots li button:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  line-height: 1;
  position: static;
  padding: 0;
}

body.dark-mode .slick-dots li button {
  background-color: var(--white3);
}
body.dark-mode .slick-dots li.slick-active button {
  background-color: var(--fusya);
}

.news-popup-active .slick-slide > div > .desc > .text {
  display: none;
}

.slick-dots {
  position: static;
  padding: 0;
}

.comments {
  padding:40px;
  border:1px solid var(--fusya);
  border-radius: 16px;
  position: relative;
  z-index: 1;
}
body.dark-mode .comments {
  border-color: var(--dark3);
}
.comments i#comment-place-holder {
  display: none;
}
.comments i#comment-place-holder + * {
  margin-top: unset;
}
#post-popup .comments i#comment-place-holder {
  display: block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 1px;
  font-size: 0;
  inset: -200px auto auto;
}

.comments .comment {
  position: relative;
  z-index: 1;
}

.comments > .comment + .comment,
.comments > .comment-reply-form + .comment,
.login-needed + .comment {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid var(--fusya);
}
body.dark-mode .comments > .comment + .comment,
body.dark-mode .comments > .comment-reply-form + .comment,
body.dark-mode .login-needed + .comment   {
  border-top-color: var(--dark3);
}
.comments .comment > .comment {
  margin-left: 40px;
  margin-top: 20px;
}
.comment-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:20px;
  align-items: center;
}
.comment-header picture {
  width: 46px;
  height: 46px;
  display: block;
  padding: 3px;
  border:1px solid var(--dark4);
  border-radius: 50%;
}
.comment-header picture > img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.comment-content {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--softgray);
  position: relative;
  z-index: 3;
  margin-top: 10px;
}
body.dark-mode .comment-content {
  background-color: var(--dark2);
}
.comment-content:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 15px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent var(--softgray) transparent;
}
body.dark-mode .comment-content:before {
  border-color: transparent transparent var(--dark2) transparent;
}
.sub-comment:before {
  content: "";
  display: block;
  width: 16px;
  height: 24px;
  position: absolute;
  z-index: 1;
  right: 100%;
  top:0;
  border:1px solid var(--fusya);
  border-width: 0 0 1px 1px;
  border-radius: 0 0 0 6px;
}
body.dark-mode .sub-comment:before {
  border-color:var(--dark4) ;
}
.author-meta > time {
  display: block;
  margin-top: 5px;
  font-size: .8rem;
  color: var(--dark4);
}
.comment-content .action {
  position: absolute;
  z-index: 1;
  inset: auto 20px -10px auto;
}

.reply-form {
  border: 1px solid var(--dark4);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  z-index: 1;
  margin-top: 15px;
  animation: tableturn .3s normal linear;
  transform-origin: center top;
}
.reply-form:before,
.reply-form:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 1px;
  height: 16px;
  background-color: var(--dark4);
  inset: auto auto 100% 10%;
}
.reply-form:after {
  inset: auto 10% 100% auto;
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 1;
}
.form-row:focus-within {
  z-index: 2;
}
.form-row > * {
  flex:1;
  position: relative;
  z-index: 1;
}
.form-row > *,
.form-row > * + * {
  margin: 0;
}
.form-row.two,
.form-row.three {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-row + .form-row {
  margin-top: 20px;
}

span.error {
  display: inline-block;
  line-height: 1.1;
  background-color: var(--red);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 3px;
  font-size: .8rem;
}
.form-row span.error {
  position: absolute;
  z-index: 1;
  inset: auto 10px 100% auto;
}

.plase-login {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}
.plase-login .right {
  position: relative;
  z-index: 1;
}

button[type="button"].go-user-box {
  font-size: 2.1rem;
  color: var(--fusya);
}

button[type="button"].go-comment {
  font-size: 2rem;
  position: relative;
  z-index: 2;
}
button[type="button"].go-comment > em {
  font-style: normal;
  font-size: .8rem;
  line-height: 1;
  position: absolute;
  z-index: 1;
  margin: auto;
  inset:0;
  height: max-content;
}

button[type="button"].go-more-comments {
  padding: 10px 20px;
  border: 1px solid var(--dark4);
}
button[type="button"].go-more-comments:hover {
  background-color: var(--dark4);
  color: var(--white);
}

.there-is-an-error {
  background-color: var(--red);
  padding: 15px;
  color: var(--white);
  text-align: center
}
.there-is-an-error + * {
  margin-top: 16px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;  
}
.actions > * {
  flex:auto
}

button[type="button"].go-cancel,
button[type="button"].go-confirm {
  padding: 10px;
  border-radius: 3px;
}
button[type="button"].go-confirm {
  background-color: var(--green);
  color: var(--white);
}
button[type="button"].go-cancel {
  background-color: var(--red);
  color: var(--white);
}

.user-login-box {
  position: relative;
  z-index: 1;
}
.user-menu {
  position: absolute;
  z-index: 1;
  inset:100% 0 auto auto;
  padding: 20px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(0,0,0,.15);
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  content-visibility: hidden;
  margin-top: 20px;
  text-align: center;
}
.user-menu.active {
  opacity: 1;
  visibility: visible;
  content-visibility: visible;
  margin-top: 0;
}
body.dark-mode .user-menu {
  background-color: var(--dark2);
}

.user-menu .user-avatar {
  width: 46px;
  height: 46px;
  margin: 0 auto 0 auto;
  background-color: var(--white);
  border:1px solid var(--dark4);
  border-radius: 50%;
  padding: 2px;
}
.user-menu .user-avatar > img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.user-menu .closeme {
  position: absolute;
  z-index: 2;
  inset:10px 10px auto auto;
}

.go-user-menu i.heady {
  font-size: 2.1rem;
  display: inline-block;
  vertical-align: middle;
}

.notifies {
  position: fixed;
  z-index: 100001;
  inset: auto auto 20px 20px;
  max-width: 320px;
}
.notifies > * + * {
  margin-top: 36px;
}
.notify {
  border-radius: 6px;
  padding: 20px 20px 20px 64px;
  background-color: var(--dark);
  color: var(--white2);
  position: relative;
  z-index: 1;
  box-shadow: 0 0 16px rgba(0,0,0,.15);
  min-height: 80px;
}
body.dark-mode .notify:not(.red,.green) {
  background-color: var(--white3);
  color: var(--black2);
}
.notify.red {
  background-color: var(--red);
}
.notify.green {
  background-color: var(--green);
}
.notify + .notify {
  margin-top: 20px;
  animation: frombottom_mini .3s normal linear;
}
.notify:after {
  content: "\E808";
  font-family: 'heady';
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: .8rem;
}
.notify i.heady {
  font-size: 2rem;
  position: absolute;
  z-index: 1;
  inset: 20px auto auto 20px;
}

.grid-article.grid-categories {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.grid-article.grid-categories > article {
  padding: 0;
}
.grid-categories > article > h2 {
  position: absolute;
  z-index: 1;
  inset: 0;
  height: max-content;
  width: max-content;
  max-width: 80%;
  margin: auto;
  background-color: rgba(0,0,0,.65);
  padding: 10px 20px;
  border-radius: 20px;
}
.grid-categories > article > h2 + * {
  margin-top: 0;
}
.grid-categories > article:hover > h2 {
  background-color: rgba(0,0,0,.95);
}
.grid-categories > article:hover img {
  transform: scale(1.2);
}

.grid-article.grid-catposts {
  all:unset; /* default first class props */
  display:grid;   
  grid-auto-flow:column;
  gap:20px;
  overflow: auto;
}
.grid-article.grid-catposts > * {
  grid-column: unset;
  width: 330px;
}

.category-selection > h2 {
  font-size: 2.8rem;
  position: relative;
  z-index: 1;
}
.category-selection > h2 > span {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: -2rem;
}
.category-selection > h2 > span:before {
  content: "";
  position: absolute;
  display: block;
  z-index: 1;
  inset: 0;
  height: 30%;
  width: 100%;
  margin: auto;
  background-color: rgba(0,0,0,.25);
}
body.dark-mode .category-selection > h2 > span:before {
  background-color: rgba(0,0,0,.55);
}
.category-selection > h2 > span:after {
  content:'\E810';
  font-family: 'heady';
  font-weight: normal;
  font-size: 1.6rem;
  position: absolute;
  z-index: 2;
  left: 5px;
  top: 10px;
  transform: rotate(-25deg);
}
.category-selection > h2 > span > em {
  font-style: normal;
  position: relative;
  z-index: 2;
}
.category-selection > h2 + .grid-article.grid-catposts,
.category-selection > .grid-article.grid-catposts + * {
  margin-top: 16px;
}

.category-selection > .grid-article.grid-catposts + h2 {
  margin-top: 36px;
}

.category-selection > h2 > a.cat-link {
  font-size: 1rem;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  border: 1px solid var(--dark4);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  inset: 0 0 0 auto;
  width: max-content;
  height: max-content;
  margin: auto 0 auto auto;
}

.grid-article.grid-catposts > .go-to-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
.grid-article.grid-catposts > .go-to-more em {
  font-style: normal;
  font-weight: bold;
}
.grid-article.grid-catposts > .go-to-more > a {
  display: block;
  text-align: center;
}
.grid-article.grid-catposts > .go-to-more i.heady {
  font-size: 4rem;
  display: block;
  margin: 20px auto 0 auto;
}
.grid-article.grid-catposts {
  margin-right: -20px;
  margin-left: -20px;
  padding: 20px;
}
.grid-article.grid-catposts > .go-to-more > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.grid-article.grid-catposts > .go-to-more > a {
  position: absolute;
  z-index: 2;
  background-color: rgba(0,0,0,.65);
  padding: 20px;
  border-radius: 12px;
  margin: auto;
  inset: 0;
  height: max-content;
  width: max-content;
  color: #ffffff;
}

.register-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 36px;
  align-items: start;
  max-width: 640px;
  padding: 36px;
  margin-left: auto;
  margin-right: auto;
  border:1px solid var(--dark4);
  border-radius: 10px;
  background-color: var(--softgray);
}
body.dark-mode .register-box {
  background-color: var(--dark2);
  border-color: var(--black2);
}
.register-box > * {
  flex:1;
  position: relative;
  z-index: 1;
}
.register-box .left i.heady {
  font-size: 8rem;
}
.user-register-form .form-row > p {
  position: relative;
  z-index: 1;
}
.user-register-form .form-row > p:hover {
  z-index: 2;
}
[class*="-form"] .form-row .go-pass-visible  {
  position: absolute;
  z-index: 2;
  inset:0 10px 0 auto;
  margin: auto;
  width: max-content;
  height: max-content;
}

.message-box {
  background-color:#33a900;
  color: #ffffff;
  border-radius: 10px;
  color: #ffffff;
  padding: 20px;
}

iframe,
.form-row > div > div > div[style] {
  max-width: 100%;
}
,
.form-row > div > div > div[style] {
  width: 100% !important;
}

.register-call {
  padding: 20px;
  background-color: #f8f8f8;
  border:1px solid #bdbdbd;
  border-radius: 10px;
}
.register-call > a[href*="/register"] {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 0 0;
}
body.dark-mode .register-call {
  background-color: var(--dark);
  border-color: var(--dark3);
}

.plase-login .register-call {
  margin-left: -36px;
  border-radius: 0 10px 10px 0;
  border-left: 0;
  padding-left: 36px;
  max-width: max-content;
}

#post-detail .category-selection.related {
  margin-top: 3vw;
}

.post-detail-meta {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #ebebeb;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
}
body.dark-mode .post-detail-meta {
  background-color: var(--dark2);
}
.post-detail-meta > * {
  margin: 0;
  padding: 20px;
}

/* _last for all screen */

.t300,
a,
a:after,
a:before,
.share-links > a,
.share-links i.heady,
button,
.grid-article > article,
.grid-news > article > picture,
.grid-categories > article > h2,
.grid-categories > article > picture,
.grid-categories > article > picture img,
.user-menu {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.t700,
main.main-site,
main > header,
aside,
aside:after,
.sipa-popup:before,
.sipa-popup:after,
.sipa-popup > .in-box,
.the-content {
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
@keyframes fadeforever {
  0% {opacity: 1;}
  50% {opacity: .1;}
  100% {opacity: 1;}
}
@keyframes turnforever {
  0% {transform: rotate(0);}
  100%  {transform: rotate(360deg);}
}

@keyframes frombottom {
  0% {transform: translateY(101vh);}
  100% {transform: translateY(0);}
}
@keyframes frombottom_mini {
  0% {transform: translateY(20px);opacity: 0;}
  100% {transform: translateY(0);opacity: 1;}
}
@keyframes fromtop_mini {
  0% {transform: translateY(-20px);opacity: 0;}
  100% {transform: translateY(0);opacity: 1;}
}
@keyframes tableturn {
  0% {transform: rotateX(90deg);opacity: 0;}
  100% {transform: rotateX(0deg);opacity: 1;}
}


@keyframes flippo {
  0% {transform: rotateY(90deg);opacity: 0;}
  100% {transform: rotateY(0deg);opacity: 1;}
}



@keyframes zipcik {
  0% {transform: translateY(0);opacity: 0;}
  100% {transform: translateY(-300%);opacity: 1;}
}

@keyframes fromleft {
  0% {transform: translateX(-52vw);opacity: 0;}
  100% {transform: translateX(0);opacity: 1;}
}
@keyframes toleft {
  0% {transform: translateX(0);opacity: 1;}
  100% {transform: translateX(-52vw);opacity: 0;}
}


/* responsive */
@media screen and (max-width:1441px) {
  .pg {
    max-width: unset;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width:1281px) {
  .the-content {
    max-width: unset;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width:1025px) { 
  h1.logo {
    font-size: 1rem;
  }
  .category-selection > h2 {
    font-size: 1.8rem;
  }
  .category-selection > h2 > span:after {
    font-size: 1.2rem;
    left: 15px;
    top: 5px;
  }
  .hide-on-mobile {
    display: none;
  }
  .share-links > a {
    margin: 2.5px;
  }
  .plase-login .register-call {
    margin-left: unset;
    max-width: unset;
    border-radius: 0;
    border-width: 1px 0 0;
    padding: 20px;
  }
}
@media screen and (orientation:landscape) {
  .grid-news > article:nth-child(1),
  .grid-news > article:nth-child(9n+1) {
    grid-column: span 2;
  }
  main.main-site {
    max-width: 100vw;
    margin-right: 0;
    margin-left: 0;
  }
  body.news-popup-active main.main-site {
    max-width: calc(50vw - 80px);
    margin-right: 40px;
    margin-left: calc(50vw + 40px);
  }
  body.news-popup-active main.main-site > footer {
    margin-left: -40px;
    margin-right: -40px;
  }
  #post-popup .post-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
  }
  .plase-login {
    flex-direction: row;
  }
  .plase-login > * {
    flex:1
  }
  .grid-categories > article.pick {
    grid-column: span 2;
    grid-row: span 2;
  }
}


@media screen and (orientation:landscape)and (max-width:969px) {
  .grid-article {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr))
  }
  .news-popup-active .grid-article {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }
  .news-popup-active  .grid-news > article:nth-child(1), .news-popup-active .grid-news > article:nth-child(9n+1) {
    grid-column:span 1
  }
  .news-popup-active  .slick-slide > div > .desc {
    position: relative;
    z-index: 2;
    inset: auto;
    margin: -30px 10px 0 10px;
    max-width: unset;
  }
  #post-detail > .post-header > h1, #post-popup > div > .post-header > h1 {
    font-size: 1.4rem;
  }
  .post-header > a.go-back i.heady, .post-header > .share-action i.heady{
    font-size: 1.2rem;
  }
  #post-popup > div > .post-header {
    position: static;
  }
  .post-header > * {
    padding: 12px;
  }
  body.news-popup-active main.main-site {
    max-width: 50vw;
    margin-right: 0;
    margin-left: 50vw;
  }
}


@media screen and (orientation:portrait) {
  main > header {
    padding-left: 0;
    padding-right: 0;
  }
  main > header > .pg {
    gap: 10px;
  }
  #post-popup {
    z-index: 550;
    inset: 0 auto 0 0;
  }
  .post-header {
    flex-wrap: wrap;
  }
  #post-detail > .post-header {
    margin: 0 -20px 26px -20px;
  }
  #post-detail > .post-header > h1 {
    margin-top: -26px;
  }
  #post-detail > .post-header > h1,
  #post-popup > div > .post-header > h1 {
    font-size: 1.4rem;
  }
  .post-header > .share-action {
    max-width: 50%;
    text-align: right;
    padding: 12px 24px;
  }
  .post-header > a.go-back,
  .post-header > button.go-back {
    max-width: 50%;
    text-align: left;
    padding: 12px 24px;
  }
  #post-detail > .post-header > h1,
  #post-popup > div > .post-header > h1 {
    min-width: 100%;
    order: -1;
    border-width: 0 0 1px 0;
  }
  .post-header > a.go-back > i.heady,
  .post-header > button.go-back > i.heady,
  .post-header > .share-action > i.heady {
    font-size: 1.2rem;
  }
  body.news-popup-active {
    overflow: hidden;
  }
  #post-popup {
    width: 100vw;
    box-shadow: none;
  }
  #post-popup .post-image {
    margin-left: -26px;
    margin-right: -26px;
    display: block;
  }
  #post-popup .post-image > img {
    width: 100%;
  }
  #post-popup > div > .post-header {
    margin-bottom: 0;
  }
  .post-header > .post-date {
    background-color: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    max-height: max-content;
    max-width: max-content;
    inset: 0 0 16px 0;
    margin: auto auto 0 auto;
    min-width: unset;
  }
  .sipa-popup > .in-box {
    margin-left: 20px;
    margin-right: 20px;
  }
  .slick-slide > div > .desc {
    position: relative;
    inset: auto;
    max-width: unset;
    margin: -40px 20px 0 20px;
    padding: 16px;
  }
  .slick-slide > div > .desc > h2 {
    font-size: 1.2rem;
  }
  .slick-slide > div > .desc > .text {
    display: none;
  }
  .the-content > * + * {
    margin-top: 5vh;
  }
  .slick-slider.home-promo-slide .slick-list {
    box-shadow: none;
  }
  .sipa-popup > .in-box {
    padding: 26px;
  }
  button.slick-next, button.slick-prev {
    display: none !important;
  }
  .comments {
    padding: 20px 0 10px 0;
    border-width:1px 0 0;
    border-radius: 0;
  }
  .comments .comment > .comment {
    margin-left: 20px;
  }
  .form-row.two > *, .form-row.three > * {
    min-width: 100%
  }
  .go-user-menu > span {
    display: none;
  }
  .notifies {
    inset: auto 20px 20px 20px;
    max-width: unset;
  }
  .notify + .notify {
    margin-top: 5px;
  }
  button[type="button"].go-write-comment {
    inset: auto 0 auto auto;
  }
  
  .grid-article.grid-catposts > * {
    width: 70vw;
  }
  .register-box > .left {
    display: flex;
    flex-direction: row-reverse;
    gap:20px;
    align-items: center;
  }
  .register-box > .left > p + p {
    margin-top: 0;
  }
  .register-box > .left i.heady {
    font-size: 3.6rem;
  }

  .category-selection > h2 > a.cat-link {
    font-size: 0;
  }
  .category-selection > h2 > a.cat-link i.heady {
    font-size: 1rem;
  }

  #post-detail .category-selection.related {
    margin-top: 3vh;
  }
  
  @keyframes fromleft {
    0% {transform: translateX(-102vw);opacity: 0;}
    100% {transform: translateX(0);opacity: 1;}
  }
  @keyframes toleft {
    0% {transform: translateX(0);opacity: 1;}
    100% {transform: translateX(-102vw);opacity: 0;}
  }
}