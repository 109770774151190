@font-face {
    font-family: 'heady';
    src: url('heady.eot?80840609');
    src: url('heady.eot?80840609#iefix') format('embedded-opentype'),
         url('heady.woff2?80840609') format('woff2'),
         url('heady.woff?80840609') format('woff'),
         url('heady.ttf?80840609') format('truetype'),
         url('heady.svg?80840609#heady') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'heady';
      src: url('heady.svg?80840609#heady') format('svg');
    }
  }
  */
  .heady[class^="icon-"]:before, .heady[class*=" icon-"]:before {
    font-family: "heady";
    font-style: normal;
    font-weight: normal;
    speak: never;
  
    display: inline-block;
    text-decoration: inherit;
    width: auto;
    text-align: center;
    /* opacity: .8; */
  
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
  
    /* fix buttons height, for twitter bootstrap */
    line-height: 1;
  
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
  
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
  
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  
.icon-star:before { content: '\e800'; } /* '' */
.icon-star-empty:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-heart:before { content: '\e803'; } /* '' */
.icon-star-half:before { content: '\e804'; } /* '' */
.icon-camera:before { content: '\e805'; } /* '' */
.icon-picture:before { content: '\e806'; } /* '' */
.icon-ok:before { content: '\e807'; } /* '' */
.icon-cancel:before { content: '\e808'; } /* '' */
.icon-plus-circled:before { content: '\e809'; } /* '' */
.icon-minus-circled:before { content: '\e80a'; } /* '' */
.icon-help-circled:before { content: '\e80b'; } /* '' */
.icon-info-circled:before { content: '\e80c'; } /* '' */
.icon-link:before { content: '\e80d'; } /* '' */
.icon-attach:before { content: '\e80e'; } /* '' */
.icon-lock:before { content: '\e80f'; } /* '' */
.icon-pin:before { content: '\e810'; } /* '' */
.icon-eye:before { content: '\e811'; } /* '' */
.icon-eye-off:before { content: '\e812'; } /* '' */
.icon-tag:before { content: '\e813'; } /* '' */
.icon-bookmark:before { content: '\e814'; } /* '' */
.icon-thumbs-up:before { content: '\e815'; } /* '' */
.icon-thumbs-down:before { content: '\e816'; } /* '' */
.icon-folder-open:before { content: '\e817'; } /* '' */
.icon-edit:before { content: '\e818'; } /* '' */
.icon-retweet:before { content: '\e819'; } /* '' */
.icon-bell:before { content: '\e81a'; } /* '' */
.icon-attention:before { content: '\e81b'; } /* '' */
.icon-location:before { content: '\e81c'; } /* '' */
.icon-trash-empty:before { content: '\e81d'; } /* '' */
.icon-doc:before { content: '\e81e'; } /* '' */
.icon-cog:before { content: '\e81f'; } /* '' */
.icon-logout:before { content: '\e820'; } /* '' */
.icon-volume-off:before { content: '\e821'; } /* '' */
.icon-volume-up:before { content: '\e822'; } /* '' */
.icon-right-big:before { content: '\e823'; } /* '' */
.icon-left-big:before { content: '\e824'; } /* '' */
.icon-arrows-cw:before { content: '\e825'; } /* '' */
.icon-play:before { content: '\e826'; } /* '' */
.icon-play-circled2:before { content: '\e827'; } /* '' */
.icon-globe:before { content: '\e828'; } /* '' */
.icon-flash:before { content: '\e829'; } /* '' */
.icon-flight:before { content: '\e82a'; } /* '' */
.icon-leaf:before { content: '\e82b'; } /* '' */
.icon-asterisk:before { content: '\e82c'; } /* '' */
.icon-fire:before { content: '\e82d'; } /* '' */
.icon-chart-bar:before { content: '\e82e'; } /* '' */
.icon-credit-card:before { content: '\e82f'; } /* '' */
.icon-zoom-in:before { content: '\e830'; } /* '' */
.icon-zoom-out:before { content: '\e831'; } /* '' */
.icon-search:before { content: '\e832'; } /* '' */
.icon-search-1:before { content: '\e833'; } /* '' */
.icon-export:before { content: '\e834'; } /* '' */
.icon-bag:before { content: '\e835'; } /* '' */
.icon-box-1:before { content: '\e836'; } /* '' */
.icon-paper-plane-1:before { content: '\e837'; } /* '' */
.icon-rocket:before { content: '\e838'; } /* '' */
.icon-chart-line-1:before { content: '\e839'; } /* '' */
.icon-calendar:before { content: '\e83a'; } /* '' */
.icon-clock:before { content: '\e83b'; } /* '' */
.icon-newspaper-1:before { content: '\e83c'; } /* '' */
.icon-attach-1:before { content: '\e83d'; } /* '' */
.icon-pencil-alt:before { content: '\e83e'; } /* '' */
.icon-bicycle:before { content: '\e83f'; } /* '' */
.icon-spin6:before { content: '\e840'; } /* '' */
.icon-spin4:before { content: '\e841'; } /* '' */
.icon-feather:before { content: '\e842'; } /* '' */
.icon-hourglass:before { content: '\e843'; } /* '' */
.icon-link-1:before { content: '\e844'; } /* '' */
.icon-share:before { content: '\e845'; } /* '' */
.icon-phone:before { content: '\e846'; } /* '' */
.icon-docs:before { content: '\e847'; } /* '' */
.icon-bell-1:before { content: '\e848'; } /* '' */
.icon-sipa:before { content: '\e849'; } /* '' */
.icon-user:before { content: '\e84a'; } /* '' */
.icon-user-2:before { content: '\e84b'; } /* '' */
.icon-user-3:before { content: '\e84c'; } /* '' */
.icon-user-cry:before { content: '\e84d'; } /* '' */
.icon-user-blink:before { content: '\e84e'; } /* '' */
.icon-user-suprise:before { content: '\e84f'; } /* '' */
.icon-user-tongue:before { content: '\e850'; } /* '' */
.icon-user-happy:before { content: '\e851'; } /* '' */
.icon-user-maskot:before { content: '\e852'; } /* '' */
.icon-user-maskot-2:before { content: '\e853'; } /* '' */
.icon-trash:before { content: '\f083'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-keyboard:before { content: '\f11c'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-box:before { content: '\f187'; } /* '' */
.icon-pagelines:before { content: '\f18c'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-child:before { content: '\f1ae'; } /* '' */
.icon-recycle:before { content: '\f1b8'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-lifebuoy:before { content: '\f1cd'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-newspaper:before { content: '\f1ea'; } /* '' */
.icon-wifi:before { content: '\f1eb'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-user-secret:before { content: '\f21b'; } /* '' */
.icon-street-view:before { content: '\f21d'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-shopping-bag:before { content: '\f290'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
